import {scrolEvents} from './default';
import {index} from './index';
import {vues} from './vues';

document.addEventListener('DOMContentLoaded', function() {
    var ua = window.navigator.userAgent.toLowerCase();
    if(ua.indexOf("windows nt") !== -1) {
        document.body.classList.add('windows');
    }
    
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
    vues();
	scrolEvents(controller , scene);
    index();
    
});