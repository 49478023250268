import Swiper from 'swiper';

export function index(){
    if(document.getElementById('firstview')){
        const fv = document.getElementById('firstview');
        setTimeout(function(){
            fv.classList.add('active');
        },600);
    }
    
    if(document.getElementById('particles')){
        const particle = particlesJS("particles", {
          particles: {
            number: { value: 80, density: { enable: true, value_area: 800 } },
            color: { value: "#ffffff" },
            shape: {
              type: "circle",
              stroke: { width: 0, color: "#000000" },
              polygon: { nb_sides: 5 },
              image: { src: "img/github.svg", width: 100, height: 100 }
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false }
            },
            size: {
              value: 3,
              random: true,
              anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
            },
            line_linked: {
              enable: false,
              distance: 150,
              color: "#ffffff",
              opacity: 0.6,
              width: 1
            },
            move: {
              enable: true,
              speed: 6,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: { enable: false, rotateX: 600, rotateY: 1200 }
            }
          },
          interactivity: {
            detect_on: "window",
            events: {
              onhover: { enable: false, mode: "bubble" },
              onclick: { enable: false, mode: "remove" },
              resize: true
            },
            modes: {
              grab: { distance: 400, line_linked: { opacity: 1 } },
              repulse: { distance: 200, duration: 0.4 },
              push: { particles_nb: 4 },
              remove: { particles_nb: 2 }
            }
          },
          retina_detect: true
        });
    }

 
    
    const indexswipe =  document.getElementById('fvswipe');
    if(indexswipe){
        let plam = {
            slidesPerView: 1,
            centeredSlides: false,
			effect: 'fade',
			speed: 2000,
            spaceBetween: 0,
            loop: true,
			breakpoints: {
				768: {
					slidesPerView: 1,
					spaceBetween: 30,
					centeredSlides: false
				}
			},
			on: {
				init: function () {
					slideEve(this.slides[this.activeIndex]);
				},
			  	slideChangeTransitionStart: function () {
					slideEve(this.slides[this.activeIndex]);
				},
			},
        };
        const fvswipe = new Swiper('#fvswipe' , plam);
	
		function slideEve(el){
			if(el.children[0].classList.contains('video')){
				el.children[0].currentTime = 0;
				el.children[0].play();
				setTimeout(function(){
					fvswipe.slideNext();
				},8000);
			}else{
				setTimeout(function(){
					fvswipe.slideNext();
				},4000);
			}
		}
    }
	

	
    
    const conswipe =  document.getElementById('conswipe');
    if(conswipe){
        let conplam = {
            autoplay: {
            	delay: 2000,
            	disableOnInteraction: true,
            },
            slidesPerView: 1,
            centeredSlides: false,
			effect: 'fade',
			speed: 2000,
            spaceBetween: 0,
            loop: true,
			breakpoints: {
				768: {
					slidesPerView: 1,
					spaceBetween: 30,
					centeredSlides: false
				}
			}
        };
        new Swiper('#conswipe' , conplam);
    }
    
    const buswipe =  document.getElementById('buswipe');
    if(buswipe){
        let plam = {
            slidesPerView: 'auto',
            centeredSlides: false,
			speed: 600,
            spaceBetween: 40,
            loop: false,
            navigation: {
                nextEl: '.buswipe-pager-next',
                prevEl: '.buswipe-pager-prev',
            },
			breakpoints: {
				768: {
					slidesPerView: 2.5,
					spaceBetween: 30,
					centeredSlides: false
				}
			}
        };
        new Swiper('#buswipe' , plam);
    }
    
    const prswipe =  document.getElementById('prswipe');
    if(document.getElementById('prswipe')){
        let plelem = document.getElementById('prswipe');
        const plam = {
            autoplay: {
            	delay: 2000,
            	disableOnInteraction: false,
            },
            slidesPerView: 'auto',
            centeredSlides: true,
            speed: 600,
            spaceBetween: 30,
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            on: {
                resize: function(){
                    plelem.destroy(false,true);
                    plelem = new Swiper("#prswipe",plam);
                }
            },
			breakpoints: {
				768: {
					slidesPerView: 1,
					spaceBetween: 30,
					centeredSlides: false
				}
			}
        };
        plelem = new Swiper('#prswipe' , plam);
    }

}