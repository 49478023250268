export function vues(){
    const v = new Vue({
        el: '#site-header',
        data: {
            header:{
                flag: false,
                onlineshop: false,
                company: false
            }
        }
    });
}